<template>
  <Header />
  <div class="container">
    <div class="row">
      <Sidebar
        class="col-md-3 col-lg-2"
        :items="['Início', 'Cadastros', '- Usuário', '- Comunidade']"
        :routes="['/admin', '/admin/registers', '/admin/new', '/admin/comunity']"
      />
      <main class="main col-md-9 col-lg-10 px-md-4">
      <h4 style="margin-top: 20px;">INICIAL</h4>
      <div class="row">
        <form class="search-block col-md-8 mt-3">
          <h6 class="mt-3 text">Busca Avançada</h6>
          <div class="row">
            <div class="col-md-5">
              <label for="name" class="form-label">Nome do aluno</label>
              <input
                type="text"
                class="form-control"
                id="name"
                v-model="name"
              />
            </div>
            <div class="col-md-4">
              <label for="rg" class="form-label">RG</label>
              <input type="text" class="form-control" id="rg" v-model="rg" />
            </div>
            <div class="col-md-2">
              <label class="form-check-label me-2" for="active">Ativo</label>
              <label class="switch">
                <input type="checkbox" id="togBtn" v-model="active" />
                <div class="slider round">
                  <span class="on">Sim</span>
                  <span class="off">Não</span>
                </div>
              </label>
            </div>
            <div class="col-md-12 p-3 d-flex justify-content-center">
              <button id="search" class="button search ">Buscar</button>
            </div>
          </div>
        </form>
        <div class="col-md-3 mt-3">
          <router-link to="/admin/new" id="new" class="button new" @click="removeUpdatable">
            <span class="material-icons">person_add</span>
            Novo Cadastro
          </router-link>
        </div>
        </div>
        <h5 class="mt-3 text">Cadastros</h5>
        <table class="table">
          <tbody>
            <tr v-for="(item, id) in list" :key="id">
              <td>
                {{ item.attributes.FirstName + " " + item.attributes.LastName }}
              </td>
              <td class="d-flex justify-content-end">
                <span class="material-icons mx-4">
                  <a href="" style="color: #00c0c9" @click.prevent="update(item.id)">
                    edit
                  </a>
                </span>
                <span class="material-icons">
                  <router-link to="/admin" style="color: #cf3900" @click.prevent="remove(item.id)">
                    delete
                  </router-link>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-center">
          <router-link
            to="/admin/registers"
            class="button all"
            v-if="list.length > 0"
          >
            Ver todos
          </router-link>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Sidebar from "@/components/Sidebar.vue";
import Profile from "@/services/profile";
import Image from "@/services/upload";

export default {
  name: "Admin",
  components: { Sidebar, Header },
  data() {
    return {
      name: null,
      rg: null,
      active: false,
      list: [],
    };
  },
  mounted() {
    this.getAllProfiles();
  },
  methods: {
    getAllProfiles() {
      Profile.all()
        .then((response) => {
          if (response.data.data) {
            this.list = response.data.data;
            this.list.sort((a, b) => a.attributes.FirstName.localeCompare(b.attributes.FirstName));
          }
        })
        .catch((error) => {
          console.log("Erro ao recuperar profiles: " + error);
        });
    },
    remove(id) {
      var result = confirm("Deseja realmente excluir o cadastro?");
      if (result) {
        Profile.remove(id)
          .then((response) => {
            if (response.data.data.attributes.Photo.data)
              Image.remove(response.data.data.attributes.Photo.data.id)
                .catch((error) => {
                  console.log("Erro ao remover imagem: " + error);
                });
              
            this.getAllProfiles();
          })
          .catch((error) => {
            console.log("Erro ao remover profile: " + error);
          });
      }
    },
    update(id) {
      sessionStorage.setItem("updateProfileId", id);
      this.$router.push("/admin/new");
    },
    removeUpdatable() {
      sessionStorage.removeItem("updateProfileId");
    }
  }
};
</script>

<style scoped>

.container {
  min-width: 100vw;
}

.main {
  box-sizing: border-box;
  align-items: flex-start;
  border-color: transparent;
  background-color: rgb(223, 232, 240);
  min-height: 90vh;
}

input[type="text"] {
  height: 45px;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
}

h4,
h5,
h6 {
  font-style: normal;
  font-family: Ubuntu;
  line-height: normal;
  font-weight: 500;
}

h4 {
  color: #193250;
}

h5 {
  font-size: 18px;
  color: #828282;
}

h6 {
  font-size: 14px;
}

.text {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 21px;
  color: #828282;
}

label {
  font-size: 12px;
}

.active-center {
  margin-top: 35px;
}

.button {
  border: none;
  background: #033F6D;
  border-radius: 18px;
  color: white;
  justify-content: center;
  align-items: center;
}

.new {
  display: flex;
  width: 270px;
  height: 74px;
  border-radius: 30px;
  font-size: 24px;
  font-weight: 400;
}

.search-block {
  background: #FFFFFF;
  box-shadow: 0px 1px 16px -5px rgba(0, 0, 0, 0.24);
  border-radius: 12px;
}
.search {
  width: 170px;
  height: 35px;
}

.all {
  display: flex;
  width: 170px;
  height: 35px;
}

.new:hover,
.search:hover {
  background: #f8af2f;
}

table {
  width: 67%;
}

a {
  text-decoration: none;
}

tr:hover {
  border: 1px solid #f8af2f;
}

.page-item .page-link {
  border-radius: 18px;
  background: #f19700;
  color: white;
}

.page-link:hover {
  background: #f8af2f;
}

.active {
  background: #193250 !important;
}

/* checkbox slider */
.switch {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 29px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ca2222;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2ab934;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(42px);
  -ms-transform: translateX(42px);
  transform: translateX(42px);
}

.on {
  display: none;
}

.on,
.off {
  color: white;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  font-size: 12px;
  font-family: Verdana, sans-serif;
}

.on {
  left: 40%;
}

.off {
  left: 60%;
}

input:checked + .slider .on {
  display: block;
}

input:checked + .slider .off {
  display: none;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
</style>
